// Dimensions
$headerHeight: 64px;
$mobileHeaderHeight: 56px;

// colors
$primaryColor: #1e252b;
$hightLightColor: #ff6e30;
$backgroundColor: #ffffff;
$cardBackgroundColor: #ffffff;
$grayBackgroundColor: rgba(30, 37, 43, 0.05);

$panelShadowColor: rgba(0, 0, 0, 0.2);
$cardShadowColor: rgba(0, 0, 0, 0.1);

$fontBlackColor: #1e252b;
$fontGrayColor: #74777e;
$fontWhiteColor: #ffffff;

$separatorColor: #dfdfdf;
$paginationInputBorderColor: #767676;

@mixin appPadding {
  @media (min-width: 1440px) {
    padding: 0 calc(50% - 600px);
  }

  @media (max-width: 1440px) {
    padding: 0 160px;
  }

  @media (max-width: 1200px) {
    padding: 0 45px;
  }

  @media (max-width: 750px) {
    padding: 0 24px;
  }
}

.container {
  @include appPadding;
  width: 100%;
  overflow-x: hidden;
  flex: 1;
  margin-top: $headerHeight;
  background: $backgroundColor;

  @media (max-width: 750px) {
    margin-top: $mobileHeaderHeight;
  }
}

@mixin monospace {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

input::-webkit-inner-spin-button {
  display: none;
}

@mixin aspectRatio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}