@import '../../styles/common.scss';

.header {
  @include appPadding;
  display: flex;
  justify-items: flex-start;
  height: $headerHeight;
  width: 100%;
  background: $primaryColor;
  position: fixed;
  overflow: visible;
  top: 0;
  z-index: 10;

  @media (max-width: 750px) {
    height: $mobileHeaderHeight;
  }
  @media (max-width: 500px) {
    justify-content: center;
  }
}

.logo {
  display: flex;
  height: 100%;
  font-weight: 700;

  @media (max-width: 750px) {
    justify-content: center;
  }

  img {
    height: 40px;
    margin: auto 0;
  }

  span {
    margin: auto 0 auto 12px;
    font-size: 15px;
    color: $fontWhiteColor;
    white-space: nowrap;
  }
}

.menuSwitch {
  position: relative;
  display: flex;
  margin-left: 42px;
  font-size: 14px;

  &.inRight {
    margin-left: auto;
  }

  .menuSwitchName {
    display: flex;
    align-items: center;
    color: #fff;
    white-space: nowrap;
    cursor: pointer;

    .language {
      text-align: center;
    }

    .arrow {
      margin-left: 4px;
      transition-duration: 0.2s;
      width: 10px;
      height: 7px;
    }

    .icon {
      width: auto;
      height: 12px;
      margin-right: 5px;
    }
  }

  .menuSelect {
    display: flex;
    pointer-events: none;
    position: absolute;
    top: 45px;
    left: -10px;
    flex-direction: column;
    align-items: center;
    background: #fff;
    width: calc(100% + 20px);
    padding: 4px;
    border-radius: 2px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    user-select: none;
    transition: 0.1s;
    transform: scale(0.9) translateY(-10px);
    opacity: 0;
  }
}
