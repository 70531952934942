@import '../../styles/common.scss';

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 10px 0;
  background: rgba(38, 38, 38, 1);
  color: #fff;
  margin-top: auto;

  a {
    color: rgba(255, 255, 255, 0.5);
  }

  .content {
    display: flex;
    padding: 0 10px;
  }

  .logo {
    display: none;
    height: 32px;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    cursor: default;
    img {
      height: 100%;
      margin-right: 10px;
    }
  }

  .links {
    display: flex;
    font-size: 12px;
    font-weight: 600;

    & > div {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      cursor: default;
    }

    a {
      font-weight: 400;
      cursor: pointer;
      margin-bottom: 8px;
      &:first-of-type {
        margin-top: 16px;
      }
      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  @media screen and (min-width: 500px) {
    .links {
      & > div {
        margin-left: 30px;
      }
    }
    .content {
      padding: 0 20px;
    }
    .logo {
      display: flex;
    }
  }

  @media screen and (min-width: 750px) {
    .links {
      & > div {
        margin-left: 60px;
      }
    }
  }
}

.copyright {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  cursor: default;

  @media (max-width: 750px) {
    flex-direction: column-reverse;
  }

  .beian {
    display: flex;
    align-items: center;

    @media (max-width: 750px) {
      margin-bottom: 6px;
    }
  }

  img {
    width: 15px;
    height: 15px;
    margin: 0 6px;
  }

  a {
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
}
