body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('./assets/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

*[role]:focus {
  outline: none;
}

.app-panel {
  width: 100vw;
  height: 100vh;
}

a {
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.customDialog  .MuiDialog-paper {
  margin: 0 !important;
}

.customDialog .MuiDialog-paperScrollPaper {
  background: transparent !important;
  box-shadow: none !important;
  overflow: hidden !important;
}

.customDialog .MuiDialog-paperWidthSm {
  max-width: 100% !important;
}

video::-internal-media-controls-download-button {
  display: none;
}

.viewer-button {
  position: fixed !important;
}

input,textarea{
  -webkit-appearance: none;
}

model-viewer {
  --poster-color: rgba(0 , 0, 0, 0);
  --poster-image: rgba(0 , 0, 0, 0);
}
